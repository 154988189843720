import React from 'react'

import Layout from 'components/layout'
import PostPreview from 'components/post-preview'
import usePosts from 'hooks/use-posts'
import './index.scss'

export default () => {
  const posts = usePosts()

  return (
    <Layout>
      <h1 className="index-title">Recent Articles</h1>
      {posts.map(post => (
        <PostPreview post={post} key={post.slug} />
      ))}
    </Layout>
  )
}
