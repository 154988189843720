import React from 'react'
import { Link } from 'gatsby'

import './post-preview.scss'

const PostPreview = ({ post }) => (
  <article className="post-preview">
    <h3>{post.title}</h3>
    <p className="date">{post.date}</p>
    <p>{post.excerpt}</p>
    <div>
      <Link to={post.slug}>Read it</Link>
    </div>
  </article>
)

export default PostPreview
