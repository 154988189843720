import { graphql, useStaticQuery } from 'gatsby'

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          excerpt
          frontmatter {
            slug
            author
            date
            title
            tag
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map((post) => ({
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    author: post.frontmatter.author,
    date: post.frontmatter.date,
    excerpt: post.excerpt,
    tag: post.frontmatter.tag,
  }))
}

export default usePosts
